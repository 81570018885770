import React from 'react';
import styled from 'styled-components';
import { Heading } from '@atoms';

const QuoteBlockNaptimeFullWidthBar = ({ quoteData }) => {
  const { quote, author, type } = quoteData;
  return (
    <CompWrapper
      data-comp={QuoteBlockNaptimeFullWidthBar.displayName}
    >
    <QuotePadding>
      <Heading 
        headingSize='H4'
        style={{
          color: '#1B1B1B',
          fontWeight: 700,
        }}
      >
        {quote?.quote}
      </Heading>
    </QuotePadding>
    </CompWrapper>
  );
};

QuoteBlockNaptimeFullWidthBar.displayName = 'QuoteBlockNaptimeFullWidthBar';
export default QuoteBlockNaptimeFullWidthBar

const CompWrapper = styled.div`
  width: 100%;
  background-color: #E4E9F1;
`
const QuotePadding = styled.div`
  padding: 50px 50px 50px 50px;
`
